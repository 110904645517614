<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver
      v-slot="{ invalid }"
      tag="div"
      ref="observer"
      class="event"
      id="scroll-block"
    >
      <header class="event__header">
        <h3 class="event__header_title">{{ event.id ? "Edit" : "Add" }} Event</h3>
        <div class="event__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete Event"
            @click="$emit('removeEvent', event)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="
              invalid || event.search_image === '' || event.home_page_image === '' || !changed
            "
            @click="saveEvent"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <div v-if="event.status" class="form__header">
          <SimpleToggle
            placeholder="Featured"
            :disabled="
              invalid || event.search_image == '' || event.home_page_image == '' || !event.status
            "
            v-model="event.featured"
            :selected="+event.featured"
            :items="[
              { id: '1', title: 'Active' },
              { id: '0', title: 'Inactive' },
            ]"
            @input="event.featured = $event"
          ></SimpleToggle>
          <p v-if="!event.status" class="form__header_notification">
            Please make this event Enabled before using this option
          </p>
          <p v-if="!event.home_page_image" class="form__header_notification">
            Please add a homepage image before using this option
          </p>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Event Information</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Title"
              :required="true"
              :validateError="errors[0]"
              v-model="event.title"
              :element="event.title"
              @input="event.title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <div class="form__row_col form__row_col--side">
            <Toggle
              placeholder="Active Toggle"
              v-model="event.status"
              :disabled="invalid || event.search_image === ''"
              :selected="+event.status"
              :items="[
                { id: '1', title: 'Active' },
                { id: '0', title: 'Inactive' },
              ]"
              @input="event.status = $event"
            ></Toggle>
          </div>
          <div class="form__row_col form__row_col--side">
            <Toggle
              placeholder="VIP Event"
              v-model="event.vip"
              :selected="+event.vip"
              :items="[
                { id: '1', title: 'Yes' },
                { id: '0', title: 'No' },
              ]"
              @input="event.vip = $event"
            ></Toggle>
          </div>
        </div>
        <div class="form__row form__row--align-start">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true }"
          >
            <BaseSelect
              placeholder="Location"
              v-model="event.location"
              :selected="event.location"
              :items="event_types"
              :required="true"
              @input="event.location = $event.id"
            ></BaseSelect>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, path: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0] || serverError.slug"
              v-model="event.slug"
              :element="event.slug"
              @input="event.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text"
                  >Example: {{ `${url}/events/${event.slug || "path"}` }}</span
                ></template
              >
            </BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Address"
              :validateError="errors[0]"
              v-model="event.address"
              :element="event.address"
              @input="event.address = $event"
            >
            </BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <div class="form__row_col form__row_col--side">
            <DatePicker
              placeholder="Start Date"
              :required="true"
              :validateError="serverError.start_date"
              :value="event.start_date"
              v-model="event.start_date"
              @input="event.start_date = $event"
            ></DatePicker>
          </div>
          <div class="form__row_col form__row_col--side">
            <DatePicker
              placeholder="End Date"
              :required="true"
              :validateError="serverError.end_date"
              :startDate="event.start_date"
              :value="event.end_date"
              v-model="event.end_date"
              @input="event.end_date = $event"
            ></DatePicker>
          </div>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 65000 }"
            v-slot="{ errors }"
          >
            <Editor
              placeholder="Event Schedule"
              :required="true"
              :validateError="errors[0]"
              :value="event.schedule"
              @input="event.schedule = $event"
              v-model="event.schedule"
              :toolbar="['bold', 'italic']"
            ></Editor>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Event Description</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, max: 65535 }"
            v-slot="{ errors }"
          >
            <BaseTextarea
              placeholder="Description"
              :required="true"
              :validateError="errors[0]"
              v-model="event.description"
              :element="event.description"
              @input="event.description = $event"
            ></BaseTextarea>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Button Text"
              :validateError="errors[0]"
              v-model="event.button_text"
              :element="event.button_text"
              @input="event.button_text = $event"
            >
            </BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Button URL"
              :validateError="errors[0]"
              v-model="event.button_url"
              :element="event.button_url"
              @input="event.button_url = $event"
            >
            </BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="RSVP Button URL"
              :validateError="errors[0]"
              v-model="event.button_rsvp_url"
              :element="event.button_rsvp_url"
              @input="event.button_rsvp_url = $event"
            >
            </BaseInput>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side">
            <Toggle
              placeholder="Safety Protocol"
              v-model="event.safety_protocol"
              :selected="+event.safety_protocol"
              :items="[
                { id: '1', title: 'Active' },
                { id: '0', title: 'Inactive' },
              ]"
              @input="event.safety_protocol = $event"
            ></Toggle>
          </div>
        </div>
        <div class="form__row" v-if="+event.safety_protocol === 1">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{
              required: true,
              min: 2,
              max: 65535,
            }"
            v-slot="{ errors }"
          >
            <Editor
              placeholder="Safety Protocol Information"
              :required="true"
              :validateError="errors[0]"
              :value="event.safety_protocol_info"
              @input="event.safety_protocol_info = $event"
              v-model="event.safety_protocol_info"
              :toolbar="[
                'heading',
                'bold',
                'italic',
                'underline',
                'link',
                'blockQuote',
                '|',
                'alignment',
                'numberedList',
                'bulletedList',
              ]"
            ></Editor>
          </ValidationProvider>
        </div>
        <div class="form__row form__row--align-start">
          <div class="form__row_col">
            <BaseSelect
              placeholder="Associated Exhibition"
              v-model="event.exhibition_id"
              :selected="event.exhibition_id"
              :items="exhibitionsList"
              :close="true"
              @input="event.exhibition_id = $event.id"
            ></BaseSelect>
          </div>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Event Images</h3>
        <div class="form__row">
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label"
              >Search Image<span class="form__row_label_required">*</span></span
            >
            <DragAndDropImage
              class="drag-and-drop--full-width"
              id="search"
              size="15"
              :image="event.search_image"
              :v-model="event.search_image"
              @input="
                event.search_image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                event.search_image = '';
              "
            ></DragAndDropImage>
          </div>
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label">Header Image </span>
            <DragAndDropImage
              class="drag-and-drop--full-width"
              id="header"
              size="15"
              :image="event.header_image"
              :v-model="event.header_image"
              @input="
                event.header_image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                event.header_image = '';
              "
            ></DragAndDropImage>
          </div>
        </div>
        <div class="form__row form__row--bordered">
          <span class="form__row_label">
            Homepage Image <span v-if="event.featured" class="form__row_label_required">*</span>
          </span>
          <DragAndDropImage
            class="drag-and-drop--full-width"
            id="home"
            size="15"
            :image="event.home_page_image"
            :v-model="event.home_page_image"
            @input="
              event.home_page_image = $event.image;
              $event.removed ? removedImages.push($event.removed) : false;
            "
            @removeImage="
              removedImages.push($event);
              event.home_page_image = '';
            "
          ></DragAndDropImage>
        </div>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import BaseTextarea from "@/components/inputs/BaseTextarea.vue";
import DragAndDropImage from "@/components/DragAndDropImage.vue";
import Editor from "@/components/inputs/Editor.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Toggle from "@/components/inputs/Toggle.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import SimpleToggle from "@/components/inputs/SimpleToggle.vue";

import IconButton from "@/components/buttons/IconButton.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseTextarea,
    BaseSelect,
    DragAndDropImage,
    Editor,
    Toggle,
    DatePicker,
    IconButton,
    IconPlus,
    IconClose,
    SimpleToggle,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      event: {
        title: "",
        status: 0,
        vip: "0",
        location: "",
        slug: "",
        address: "",
        start_date: "",
        end_date: "",
        schedule: "",
        description: "",
        button_text: "",
        button_url: "",
        button_rsvp_url: "",
        safety_protocol: "0",
        safety_protocol_info: "",
        exhibition_id: "",
        search_image: "",
        header_image: "",
        home_page_image: "",
        featured: false,
      },
      removedImages: [],
      changed: false,
    };
  },
  created() {
    if (this.item != null) {
      this.event = JSON.parse(JSON.stringify(this.item));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("general", {
      event_types: "getExhibitionTypes",
    }),
    ...mapGetters("exhibitions", {
      exhibitionsList: "getItemsList",
    }),
    ...mapGetters("events", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
  },
  watch: {
    event: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (this.$refs.observer.flags.dirty) {
            this.changed = true;
          }
          if (!val.status) {
            this.event.featured = false;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("general", ["apiPostUploadImage"]),
    ...mapMutations("events", ["setError"]),
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid &&
            this.event.search_image != "" &&
            this.event.home_page_image != ""
              ? this.saveEvent()
              : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    saveEvent() {
      let data = {
        ...this.event,
      };
      if (this.event.id) {
        this.$emit("updateEvent", { data: data, imageForRemove: this.removedImages });
      } else {
        this.$emit("createEvent", { data: data, imageForRemove: this.removedImages });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.event {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      color: $black;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>
